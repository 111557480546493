import React from "react";
import { Router } from "@reach/router";
import { login, isAuthenticated, getProfile, logout } from "../utils/auth";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import {userData} from "../data/data";

//Potential refactoring later on
import pdf1 from "../pdf/Bokslut2018-2019.pdf";
import pdf2 from "../pdf/Budget-2019-2020.pdf";
import pdf3 from "../pdf/Forvaltningsberattelse-Fornbods-Vagsamfallighetsforening.pdf";
import pdf4 from "../pdf/Fornbod-Forvaltningsberattelse-2017-19-2.pdf";
import pdf5 from "../pdf/Forvaltningsberättelse-Fornbods-Vagsamfallighetsförening-1.pdf";
import pdf6 from "../pdf/Fombods-Samfallighetsforening-Forvaltningsberattelse-2015-16.pdf";
import pdf7 from "../pdf/Forvaltningsberattelse2020.pdf";
import pdf8 from "../pdf/Bokslut2019-2020.pdf";
import pdf9 from "../pdf/Budget2020-2021.pdf";
import pdf10 from "../pdf/Revisorerna2020.pdf";
import pdf11 from "../pdf/2021/Bokslut_o_budget_2021-2022.pdf";
import pdf12 from "../pdf/2021/Forvaltningsberattelse2021.pdf";
import pdf13 from "../pdf/2021/Revisionsberattelse2021.pdf";
import pdf14 from "../pdf/Stadgar.pdf";
import pdf15 from "../pdf/forvaltningsber2022.pdf";
import pdf16 from "../pdf/Budget-2022-2023.pdf";
import pdf17 from "../pdf/Revisionsber2022.pdf";
import pdf18 from "../pdf/Horn-ga-240624.pdf";

const Home = () => <div className="wrapper"><p>Navigera genom att gå till någon av sidorna ovan</p></div>;
const Medlemmar = () => userData();
const Bokslut = () => {
  return (
    <>
      <div className="wrapper">
        <h3>Bokslut och förvaltningsberättelser</h3>
        <div className="pdfLinks">
        <h3>2022</h3>
          <a href={pdf15} target="_blank" rel="noopener noreferrer">Förvaltningsberättelse</a>
          <a href={pdf16} target="_blank" rel="noopener noreferrer">Bokslut och budget 2022</a>
          <a href={pdf17} target="_blank" rel="noopener noreferrer">Revisorerna 2022</a>
        <h3>2021</h3>
          <a href={pdf12} target="_blank" rel="noopener noreferrer">Förvaltningsberättelse</a>
          <a href={pdf11} target="_blank" rel="noopener noreferrer">Bokslut och budget 2021</a>
          <a href={pdf13} target="_blank" rel="noopener noreferrer">Revisorerna 2021</a>
        <h3>2020</h3>
          <a href={pdf7} target="_blank" rel="noopener noreferrer">Förvaltningsberättelse</a>
          <a href={pdf8} target="_blank" rel="noopener noreferrer">Bokslut 2020</a>
          <a href={pdf9} target="_blank" rel="noopener noreferrer">Budget 2020</a>
          <a href={pdf10} target="_blank" rel="noopener noreferrer">Revisorerna 2020</a>
        <h3>2019</h3>
          <a href={pdf1} target="_blank" rel="noopener noreferrer">Bokslut 2018 2019</a>
          <a href={pdf2} target="_blank" rel="noopener noreferrer">Budget 2019 2020</a>
          <a href={pdf3} target="_blank" rel="noopener noreferrer">Förvaltningsberättelse</a>
        <h3>2018</h3>
          <a href={pdf4} target="_blank" rel="noopener noreferrer">Förvaltningsberättelse</a>
        <h3>2017</h3>
          <a href={pdf5} target="_blank" rel="noopener noreferrer">Förvaltningsberättelse</a>
        <h3>2016</h3>
          <a href={pdf6} target="_blank" rel="noopener noreferrer">Förvaltningsberättelse</a>
        </div>
      </div>
    </>
  );
};

const Styrelse = () => {
  return (
    <>
      <div className="wrapper">
          <h2>Styrelse</h2>
          <div className="styrelse">
            <p className="styrelse_titel">Ordförande</p>
            <p className="styrelse_namn styrelse_line">Nina Rådström</p>
            <a className="styrelse_line" href="mailto:nina919401@gmail.com">
            nina919401@gmail.com
            </a>
          </div>
          <div className="styrelse">
            <p className="styrelse_titel">Kassör</p>
            <p className="styrelse_namn styrelse_line">Jonas Ljungberg</p>
            <a
              className="styrelse_line"
              href="mailto:jonas.ljungberg@ekh.lu.se"
            >
              jonas.ljungberg@ekh.lu.se
            </a>
          </div>
          <div className="styrelse">
            <p className="styrelse_titel">Sekreterare</p>
            <p className="styrelse_namn styrelse_line">Antonia Engström</p>
            <a className="styrelse_line" href="mailto:antonia.engstrom@gmail.com">
            antonia.engstrom@gmail.com
            </a>
          </div>
          <div className="styrelse">
            <p className="styrelse_titel">Ledamot</p>
            <p className="styrelse_namn styrelse_line">Åsa Elgqvist</p>
            <a className="styrelse_line" href="mailto:asa@bralivsutveckling.se">
            asa@bralivsutveckling.se
            </a>
          </div>
          <div className="styrelse">
            <p className="styrelse_titel">Ledamot</p>
            <p className="styrelse_namn styrelse_line">Niklas Gunhamn</p>
            <a
              className="styrelse_line"
              href="mailto:niklas@gunhamn.se"
            >
              niklas@gunhamn.se
            </a>
          </div>
          <div className="styrelse">
            <p className="styrelse_titel">Suppleant</p>
            <p className="styrelse_namn styrelse_line">Alexander Horn</p>
            <a
              className="styrelse_line"
              href="mailto:alex@hornsvingard.se"
            >
              alex@hornsvingard.se
            </a>
          </div>
          <div className="styrelse">
            <p className="styrelse_titel">Suppleant</p>
            <p className="styrelse_namn styrelse_line">Björn Fjaestad</p>
            <a
              className="styrelse_line"
              href="mailto:bjorn.fjaestad@gmail.com"
            >
              bjorn.fjaestad@gmail.com
            </a>
          </div>
          <div className="styrelse">
            <p className="styrelse_titel">Suppleant</p>
            <p className="styrelse_namn styrelse_line">Ulrika Horn</p>
            <a
              className="styrelse_line"
              href="mailto:ulrika@lowenwidman.se"
            >
              ulrika@lowenwidman.se
            </a>
          </div>
          <div className="styrelse">
            <p className="styrelse_titel">Suppleant</p>
            <p className="styrelse_namn styrelse_line">Robin Lee</p>
            <a
              className="styrelse_line"
              href="mailto:robin@atlanten.se"
            >
              robin@atlanten.se
            </a>
          </div>
          <div className="styrelse">
            <p className="styrelse_titel">Suppleant</p>
            <p className="styrelse_namn styrelse_line">Göran Johansson</p>
            <a
              className="styrelse_line"
              href="mailto:goran.johansson@longbay.se"
            >
              goran.johansson@longbay.se
            </a>
          </div>
        </div>
    </>
  );
};

const Medlem = () => {
  if (!isAuthenticated()) {
    login();
    return <div className="callback"><h1>Skickar vidare till inloggningen...</h1></div>;
  }

  const user = getProfile();
  const siteTitle = "Fornbod - Medlem";
  return (
    <Layout>
      <Helmet title={siteTitle} />
      <div className="wrapper">
        <h1>Medlemssidor</h1>
        <p>Välkommen till medlemssidorna för Fornbods samfällighetsförening. Allt material på denna 
            sida är endast tillgängligt för de som är inloggade.</p>
        <div className="memberNav">
        <Link to="/medlem/bokslut/">Bokslut</Link>{" "}
        <a href={pdf18} target="_blank" rel="noopener noreferrer">Medlemsfastigheter andelstal</a>
        <a href={pdf14} target="_blank" rel="noopener noreferrer">Stadgar</a>
        <Link to="/medlem/styrelse/">Styrelse</Link>{" "}

        
        <a
          href="#logout"
          onClick={(e) => {
            logout();
            e.preventDefault();
          }}
        >
          Logga ut
        </a>
        </div>
      </div>
      <Router>
        <Home path="/medlem/" user={user} />
        <Bokslut path="/medlem/bokslut" />
        <Medlemmar path="/medlem/medlemmar" />
        <Styrelse path="/medlem/styrelse" />
      </Router>
    </Layout>
  );
};

export default Medlem;
